import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageService } from '@ess-front/angular-components';
import { User, UserService } from '@ess-front/shared';
import { AuthService } from '../auth/auth-service/auth.service';

@Component({
  selector: 'ess-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  user$: Observable<User>;
  showUserMenu = false;

  constructor(
    private readonly authService: AuthService,
    private readonly elementRef: ElementRef,
    private readonly imageService: ImageService,
    private readonly userService: UserService,
  ) {}

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showUserMenu = false;
    }
  }

  ngOnInit(): void {
    this.user$ = this.userService.getUser$();
  }

  getImageUrl(url: string, height: number, width: number): string {
    return this.imageService.getImageUrl(url, height, width);
  }

  logOut(): void {
    this.authService.logout();
  }
}
