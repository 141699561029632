<div
  class="user__box"
  [ngClass]="{grey_background: showUserMenu}"
  [ngStyle.lt-sm]="{height: '3.5rem'}"
  (click)="showUserMenu = !showUserMenu"
  fxLayout="row wrap"
  fxLayoutAlign="end center"
  fxLayoutGap="0.625rem"
  fxFlex="1 1 100%"
  cdkOverlayOrigin
  #overlayOrigin="cdkOverlayOrigin"
>
  <ng-container *ngIf="user$ | async as user">
    <div class="user__name">
      <p>{{ user?.firstName }}</p>
    </div>
    <div
      *ngIf="user?.avatar"
      class="user__image"
      [style.backgroundImage]="'url(' + getImageUrl(user.avatar, 50, 50) + ')'"
    ></div>
    <div *ngIf="!user?.avatar" class="user__image">
      <i class="pi pi-user"></i>
    </div>
  </ng-container>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="showUserMenu"
  cdkConnectedOverlayLockPosition="true"
  cdkConnectedOverlayPanelClass="user__menu_overlay"
  cdkConnectedOverlayWidth="9.375rem"
>
  <div class="user__menu" fxFlex="1 1 100%" fxLayoutAlign="center center">
    <button mat-button fxFlex="1 1 auto" (click)="logOut()">
      <mat-icon color="primary">keyboard_backspace</mat-icon>

      <p>Log out</p>
    </button>
  </div>
</ng-template>
