import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class LoadingService {
  readonly progress: BehaviorSubject<number> = new BehaviorSubject(0);
  readonly loading$: Observable<number> = this.progress.asObservable().pipe(publishReplay(1), refCount());

  set loading(activeCalls: number) {
    this.progress.next(activeCalls);
  }
}
