import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';
import { catchError, delay, map, retryWhen, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private errorHandler: ErrorHandler;

  constructor(private readonly injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    this.errorHandler = this.injector.get(ErrorHandler);
    let request = req.clone();

    if (!request.url.includes('/login')) {
      request = req.clone({
        setHeaders: {
          authorization: this.authService.getToken() ? `token ${this.authService.getToken()}` : '',
        },
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 202 && !request.url.includes('to_pdf')) {
          throw event;
        } else {
          return event;
        }
      }),
      retryWhen(errors =>
        errors.pipe(
          delay(500),
          take(10),
          tap((error: HttpErrorResponse) => {
            if (error.status >= 400) {
              throw error;
            }
            // Retrying...
          }),
        ),
      ),
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      }),
    );
  }
}
