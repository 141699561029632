import { BaseModule } from './components/base/base.module';
import { LoadingModule } from './loading/loading.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [],
  exports: [LoadingModule],
  imports: [CommonModule, BrowserModule, BrowserAnimationsModule, LoadingModule, BaseModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {}
