<div
  class="background"
  [ngStyle]="{
    'background-image': 'url(' + '' + ')',
    'background-size': 'cover',
    'background-position': 'center',
    filter: loginMouseEnter ? 'blur(4px)' : null
  }"
  @fadeInAnimation
></div>

<div fxLayout fxLayoutAlign="center center" style="height: 100vh" @fadeInAnimation>
  <div
    class="login"
    (mouseenter)="loginMouseEnter = true"
    (mouseleave)="loginMouseEnter = false"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
  >
    <div class="login-header" fxFlex="1 1 100%" fxLayoutAlign="center center">
      <img src="assets/logos/black-logo-transparent-background.png" alt="logo" class="logo" />
    </div>

    <ess-login-widget (loggedIn)="redirectBack()" fxFlex="0 0 18.75rem" @fadeInAnimation> </ess-login-widget>
  </div>
</div>
