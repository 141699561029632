import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const result = this.authService.isAuthenticated();
    if (!result) {
      // if user is not authenticated we should redirect to login page or to BO for TB lite
      this.authService.redirectNotAuthenticatedUser();
    }
    return result;
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.canActivate(route);
  }
}
