import { ErrorHandler, NgModule } from '@angular/core';
import { ErrorsHandler } from './service/errors.handler';
import * as Sentry from '@sentry/angular';
import { environment } from '@environment';
import { release } from '@release';

Sentry.init({
  denyUrls: ['localhost'],
  dsn: environment.sentryBrainDns,
  enabled: window.location.hostname !== 'localhost',
  environment: environment.production ? 'production' : 'development',
  release: release,
  beforeSend: event => (window.location.hostname === 'localhost' ? null : event),
});
@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
  ],
})
export class ErrorsModule {}
