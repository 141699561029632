<form [formGroup]="loginForm" fxLayout="column" novalidate>
  <div class="login-content" fxLayout="column" fxLayoutAlign="start center">
    <div *ngIf="loginError" class="login-error">
      <p>Email and password doesn't match</p>
    </div>

    <mat-form-field>
      <input matInput formControlName="email" type="email" placeholder="Email" fxFlex="100" />

      <mat-error *ngIf="loginForm.get('email').hasError('required')"> Please insert your email </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        formControlName="password"
        type="password"
        (keyup.enter)="
          loginForm.valid &&
            login(loginForm.get('email').value, loginForm.get('password').value, loginForm.get('keepMeLoggedIn').value)
        "
        placeholder="Password"
        fxFlex="100"
      />

      <mat-error *ngIf="loginForm.get('password').hasError('required')"> Please insert your password </mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="keepMeLoggedIn" class="keep_me_logged">
      <small>Keep me logged in</small>
    </mat-checkbox>

    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="
        login(loginForm.get('email').value, loginForm.get('password').value, loginForm.get('keepMeLoggedIn').value)
      "
      [disabled]="loginForm.invalid || invalidErrorMessage"
    >
      <span class="italic">Log in</span>
    </button>
  </div>
</form>
