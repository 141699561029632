import { Component } from '@angular/core';
import { IconService } from '@ess-front/shared';

@Component({
  selector: 'ess-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private readonly iconService: IconService) {}
}
