import { environment } from '@environment';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationService } from '@ess-front/brain-shared';
import { AuthDataBE, UserService } from '@ess-front/shared';

/**
 * AUTH SYSTEM EXPLANATION: *
 * - All route childs but 'login' have a canActivate and canActivateChild guard
 *   that ckecks if the user is authenticated through this.authService.getToken().
 *
 * - The user's authetication is checked in every route change inside the 'admin' state.
 *   If is not authenticated, the user is redirected to the 'login' state.
 *
 * - The auth session process works like this:
 *    - The user log in with Email and Password.
 *    - The data is sent to the server.
 *    - The server responds with the user token and roles.
 *    - The token is setted in the app (localStorage/sessionStorage).
 *    - The auth.interceptor attaches the auth token to the headers of every
 *      communication with the server.
 */

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly endPoint = 'api/rest-auth/';
  private readonly tokenKey = 'ess_token';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly location: Location,
  ) {}

  login(email: string, password: string, keepMeLoggedIn: boolean): Observable<AuthDataBE> {
    this.deleteToken();
    this.userService.removeUser();
    return this.httpClient.post<AuthDataBE>(`${environment.apiEnv}${this.endPoint}login/`, { email, password }).pipe(
      tap(response => {
        if (!response.is_superuser && !response.is_staff) {
          this.notificationService.notifyWithComponent(`Your account doesn't have enough rights to access this page`, {
            duration: 10000,
            panelClass: 'error',
          });
          this.router.navigate([`/login`]);
          this.deleteToken();
        } else {
          this.notificationService.notifyWithComponent('User logged in');
          this.setToken(response.key, keepMeLoggedIn);
          this.userService.getUser$();
        }
      }),
    );
  }

  logout(): void {
    // logout() is invoked from ErrorsHandler on http response with 401 status code (when token is expired)
    this.deleteToken();
    this.userService.removeUser();
    this.redirectNotAuthenticatedUser();
  }

  getToken(): string {
    return (
      this.getCookie(this.tokenKey) || sessionStorage.getItem(this.tokenKey) || localStorage.getItem(this.tokenKey)
    );
  }

  getCookie(name: string) {
    return document.cookie
      .split(';')
      .find(el => {
        const [key, value] = el.split('=');
        return key.trim() === name;
      })
      ?.split('=')[1];
  }

  setToken(token: string, keepMeLoggedIn?: boolean): void {
    const storage = keepMeLoggedIn ? localStorage : sessionStorage;

    // Remove previous/other user's tokens
    this.deleteToken();
    storage.setItem(this.tokenKey, token);
  }

  deleteToken(): void {
    localStorage.removeItem('ess_token');
    sessionStorage.removeItem('ess_token');
    this.deleteCookie();
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  redirectNotAuthenticatedUser(): void {
    const currentUrl = this.location.path();
    this.router.navigate(['/login'], { state: { redirectUrl: currentUrl } });
  }

  private deleteCookie(): void {
    document.cookie = `${this.tokenKey}=;path=/;domain=${environment.cookiesDomain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}
