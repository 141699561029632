import { BaseComponent } from './core/components/base/component/base.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginComponent } from '@ess-front/authorization';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'countries',
        loadComponent: () =>
          import('@features/countries/ui/regions/regions.component').then(mod => mod.RegionsComponent),
      },
      {
        path: 'countries/:id',
        loadComponent: () =>
          import('@features/countries/ui/country/country.component').then(mod => mod.CountryComponent),
      },
      {
        path: 'destinations',
        loadChildren: () => import('@ess-front/destinations').then(mod => mod.DestinationsModule),
      },
      {
        path: 'billing',
        loadChildren: () => import('@ess-front/billing').then(mod => mod.BillingModule),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('@features/not-found/not-found.module').then(mod => mod.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true, paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
